
import { defineComponent, nextTick, provide } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "App",
  setup() {
    const route = useRoute();

    function scrollToTop() {
      nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        window.parent.postMessage("scroll-to-top", "*");
      });
    }

    function goToLogin() {
      window.parent.postMessage("go-to-login", "*");
    }

    function goToContactPage() {
      window.parent.postMessage("go-to-contact-page", "*");
    }

    function emitNewFrameHeight(height: any) {
      window.parent.postMessage(
        {
          frameHeight: height,
        },
        "*"
      );
    }

    provide("emit-new-frame-height", emitNewFrameHeight);
    provide("scroll-to-top", scrollToTop);
    provide("go-to-login", goToLogin);
    provide("go-to-contact-page", goToContactPage);
  },
});
